<button *ngIf="user$ | async; else loggedOut"
  class="button is-primary has-text-kokoda is-inverted is-rounded-icon mr-0 has-background-canara"
  [ngClass]="{ 'has-background-kokoda': active }" [ngbTooltip]="content" placement="bottom-right" triggers="click" [autoClose]="'outside'"
  tooltipClass="user-button-tooltip" (shown)="active = true" (hidden)="active = false">
  <svg-profil class="icon" [ngClass]="{ 'has-text-white': active, 'has-background-kokoda': active }"></svg-profil>
</button>

<ng-template #content>
  <app-user-menu></app-user-menu>
</ng-template>

<ng-template #loggedOut>
  <a routerLink="/user/sign_in" class="button is-primary has-text-kokoda is-inverted is-rounded-icon mr-0 has-background-canara">
    <svg-profil class="icon"></svg-profil>
  </a>
</ng-template>