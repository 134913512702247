<nav class="navbar is-fixed-top has-viewport-dependent-height has-max-width-100vw is-z-index-navbar" *ngIf="!(hideNavbar$ | async)"
  [ngClass]="{ 'has-shadow': shadowShown }" role="navigation" aria-label="main navigation">
  <div class="is-width-viewport is-height-100 is-flex is-align-items-center is-justify-content-space-between-touch is-relative">
    <button class="is-hidden-desktop button is-primary is-inverted ml-7px has-background-canara" (click)="openMobileNav()">
      <svg-menu class="icon has-text-kokoda"></svg-menu>
    </button>

    <div class="navbar-brand">
      <a class="navbar-item" routerLink="/">
        <svg-ye-logo-light class="is-logo-size"></svg-ye-logo-light>
      </a>
    </div>
    <ng-container *ngIf="!(hideNavbar$ | async)">
      <div class="navbar-menu ml-4">
        <div class="navbar-start">
          <a class="navbar-item is-size-5-and-half has-text-weight-semibold" routerLink="/video-kategorien" routerLinkActive="is-active"
            i18n="@navBar">Videos</a>
          <a class="navbar-item is-size-5-and-half has-text-weight-semibold" routerLink="/programme" routerLinkActive="is-active"
            i18n>Programme</a>
          <a class="navbar-item is-size-5-and-half has-text-weight-semibold" [href]="liveClassesLink" routerLinkActive="is-active"
            i18n>Live</a>
          <a class="navbar-item is-size-5-and-half has-text-weight-semibold" routerLink="/yogalehrer" routerLinkActive="is-active" i18n>
            Lehrer:innen
          </a>
          <a class="navbar-item is-size-5-and-half has-text-weight-semibold" routerLink="/magazin" routerLinkActive="is-active" i18n>
            Magazin
          </a>
          <a class="navbar-item is-size-5-and-half has-text-weight-semibold" href="https://academy.yogaeasy.de/" target="_blank"
            rel="noopener" title="Externer Link zur Academy Website" i18n-title i18n-href>
            <ng-container i18n>Academy</ng-container>
            <svg-external-link class="icon is-tiny ml-2"></svg-external-link>
          </a>

        </div>

        <div class="navbar-end">
          <div class="navbar-item">
            <div class="buttons">
              <app-language-switcher *ngIf="isLoggedIn === false" class="mr-5-desktop"></app-language-switcher>
              <app-search-button class="mr-1"></app-search-button>
              <a *ngIf="isLoggedIn === false" [href]="(siteParams$ | async)?.cta_button?.button_url || '/prices'"
                class="button is-mandy-pink is-rounded has-text-weight-semibold ml-3" [ngClass]="{ 'is-hidden': !ctaButtonShown }">
                {{ (siteParams$ | async)?.cta_button?.button_text || 'Jetzt anmelden' }}
              </a>
              <a *ngIf="isLoggedIn === false || isLoggedIn === undefined" routerLink="/user/sign_in"
                class="button is-primary is-outlined is-rounded has-text-weight-semibold ml-3"
                [ngClass]="{ 'is-loading': isLoggedIn === undefined }">
                Login
              </a>
              <app-user-button *ngIf="isLoggedIn === true" class="ml-1"></app-user-button>
            </div>
          </div>
        </div>
      </div>

      <div class="buttons is-hidden-desktop mr-3">
        <app-search-button></app-search-button>
        <app-user-button class="ml-1 mr-2"></app-user-button>
      </div>
    </ng-container>
  </div>
</nav>