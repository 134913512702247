import {
  DOCUMENT,
  NgIf,
  NgOptimizedImage,
  NgTemplateOutlet,
  provideImageKitLoader,
} from '@angular/common';
import { Component, Inject, LOCALE_ID } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { WaveSvg } from '../svg/wave/wave.svg';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subscription, map, startWith } from 'rxjs';
import { Campaign } from '../api/models/campaign';
import { CampaignService } from '../api/services/campaign.service';
import { CoreActions } from '../core/core.actions';
import { NavbarWithLogoComponent } from '../core/navbar-with-logo/navbar-with-logo.component';
import { RecommendedByComponent } from '../shared/recommended-by/recommended-by.component';

@Component({
  selector: 'app-campaign',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    WaveSvg,
    NgOptimizedImage,
    NgTemplateOutlet,
    MatInputModule,
    MatFormFieldModule,
    RecommendedByComponent,
    NgIf,
    NavbarWithLogoComponent,
  ],
  providers: [provideImageKitLoader('https://ik.imagekit.io/ye')],
  templateUrl: './campaign.component.html',
  styleUrl: './campaign.component.sass',
})
export class CampaignComponent {
  protected headerImg: string;
  private subscription: Subscription = new Subscription();
  campaignForm: UntypedFormGroup;
  protected campaignData: Campaign = {} as Campaign;
  protected campaignImg: string;
  protected buttonDisabled = true;
  protected isLoggedIn = false;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private fb: UntypedFormBuilder,
    private service: CampaignService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private store: Store,
  ) {
    this.campaignImg =
      locale === 'de'
        ? 'production/uploads/campaign/campaign_DE.jpg'
        : 'production/uploads/campaign/campaign_EN.jpg';
    this.campaignForm = this.fb.group({
      coupon: [null],
    });
  }

  ngOnInit(): void {
    this.subscription.add(
      this.store
        .select(state => state.user?.user)
        .pipe(
          map(user => user !== null),
          startWith(false),
        )
        .subscribe(isLoggedIn => {
          this.isLoggedIn = isLoggedIn;
        }),
    );
    this.subscription.add(
      this.activatedRoute.params.subscribe(params => {
        if (params.coupon) {
          this.campaignForm.get('coupon').setValue(params.coupon);
          this.onSubmit();
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onSubmit() {
    this.campaignForm.markAllAsTouched();
    this.campaignForm.markAsPristine();
    const sCoupon = this.campaignForm.get('coupon').value;
    if (sCoupon) {
      this.subscription.add(
        this.service.getCampaign(sCoupon).subscribe(result => {
          this.campaignData = result;
          if (result.coupon.is_valid) {
            this.redirect(result.coupon.redirect_to);
          } else {
            this.campaignForm.get('coupon').setErrors({});
            this.buttonDisabled = true;
          }
        }),
      );
    }
  }

  private redirect(url: string) {
    if (url.includes('/free') || url.includes('/user/profile')) {
      this.router.navigate([url]);
    } else {
      this.document.location.href = url;
    }
  }

  get couponField() {
    return this.campaignForm.get('coupon');
  }

  public onInputValueChanged(): void {
    this.buttonDisabled = false;
  }
}
