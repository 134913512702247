<li class="article-teaser has-border-radius-5 " [ngClass]="{ 'large-on-desktop': largeOnDesktop }"
  [ngClass]="showImg ? 'article-teaser-with-img': 'article-teaser-without-img'" *ngIf="teaser">
  <a class="no-link has-underline-on-hover" [href]="teaser.path" [title]="teaser.title">
    <ng-container *ngIf="!showImg; else showImg">
      <div class="header">
        <div *ngIf="teaser.tags && teaser.tags.length" class="article-tags">
          <a *ngFor="let tag of teaser.tags" [href]="'artikel-tags/' + tag.slug" class="no-link article-tag">
            {{ tag.name }}
          </a>
        </div>
        <div class="headline">
          {{ teaser.title }}
        </div>
      </div>
      <div class="main">
        <div class="description">
          {{ teaser.long_teaser }}
        </div>
        <a class="no-link more is-flex is-justify-content-center article-nav-button is-align-items-center has-font-family-source-sans-pro has-text-weight-bold is-size-6 "
          [href]="teaser.path" i18n>
          Mehr lesen
          <svg-arrow></svg-arrow>
        </a>
      </div>
    </ng-container>
    <ng-template #showImg>
      <img class="is-block has-border-radius-5-top" [ngSrc]="imagePath" [alt]="teaser.title" width="380" height="215"
        [loaderParams]="{ fallback: 'production/uploads/components/video-teaser/fallback-image.jpg' }" />
      <div class="has-background-primar is-flex is-flex-direction-column is-justify-content-space-between pt-4 px-4 pb-4 has-height-119px"
        [ngClass]="{ 'has-height-125px-desktop': largeOnDesktop }">
        <h3
          class="has-text-centered has-text-deep-sea-green has-font-family-source-sans-pro has-text-weight-bold has-min-height-53px teaser-text is-size-5 is-line-height-24">
          {{ teaser.title }}
        </h3>
        <div class="has-text-centered has-font-family-source-sans-pro has-text-weight-bold
           is-underline-on-hover-parent is-size-6 is-flex is-align-items-center is-justify-content-center article-nav-button">
          <ng-container i18n>Mehr lesen</ng-container><svg-arrow class="icon is-small ml-1"></svg-arrow>
        </div>
      </div>
    </ng-template>
  </a>
</li>