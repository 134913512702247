<div *ngIf="(redirected$ | async) === false">
  <app-navbar *ngIf="(contentOnly$ | async) === false"></app-navbar>
  <app-mobile-navigation *ngIf="(contentOnly$ | async) === false"></app-mobile-navigation>
  <app-announcement *ngIf="(contentOnly$ | async) === false"></app-announcement>
  <app-migration-banner *ngIf="(contentOnly$ | async) === false"></app-migration-banner>
  <div class="is-width-viewport">
    <div [ngClass]="isLargeScreen ? 'is-large-screen-min-height-screensize' : 'is-small-screen-min-height-screensize'">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-footer *ngIf="(contentOnly$ | async) === false"></app-footer>
  <app-sticky-cta-button *ngIf="((contentOnly$ | async) === false && isLoggedIn !== undefined)"></app-sticky-cta-button>
  <app-bottomsheet></app-bottomsheet>
  <app-toast></app-toast>
  <app-fullscreen-overlay></app-fullscreen-overlay>
</div>