@if (!(hideAnnouncement$ | async) && !isLoggedIn && locale === 'en' && !isMigrationBannerSessioned) {
<div class="announcement-wrapper" [ngClass]="{'is-display-none': isDisplayNone}">
  <div class="announcement is-width-viewport">
    <div class="">
      <h3 class="announcement-title">Welcome to YogaEasy!</h3>
      <div class="announcement-body content mb-0">EkhartYoga is now part of YogaEasy, offering the same great content with new
        possibilities.</div>
      <div class="announcement-body content mb-0"><span class="has-text-weight-bold mr-1">Already an EkhartYoga member?</span>
        <a class="announcement-link" [href]="'/user/password_reset'">Set a new password</a>
        to activate your account and access your saved content.
      </div>
      <span class="close-button" (click)="close()">
        <svg-exit-circle></svg-exit-circle>
      </span>
    </div>
  </div>
</div>
}