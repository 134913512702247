import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { filter, map } from 'rxjs/operators';
import { CoreActions } from '../core.actions';

@Injectable({
  providedIn: 'root',
})
export class NextAfterLoginService {
  constructor(
    private store: Store,
    private router: Router,
  ) {}

  init() {
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        map(e => e as NavigationEnd),
        filter(e => !e.url.includes('/user/sign_in')),
        filter(e => !e.url.includes('/user/password')),
        filter(e => !e.url.includes('/user/account_activation')),
        filter(e => !e.url.includes('/user/password_reset')),
      )
      .subscribe(e => this.store.dispatch(new CoreActions.SetNextUrl(e.url)));
  }
}
