<section class="" *ngIf="articleTeasers$ | async as articleTeasers">
  <h2 class="is-size-4 is-size-3-tablet has-text-deep-sea-green has-text-weight-bold has-text-centered px-4-touch px-7-desktop mb-2">
    <ng-container i18n>
      Die ganze Welt des Yoga im Yoga-Magazin
    </ng-container>
  </h2>
  <div class="is-size-5-and-half has-text-kokoda has-text-centered px-4-touch px-7-desktop mb-4">
    <ng-container i18n>
      Hier erfährst du alles über die ganzheitliche Wirkung der Yoga-Übungen, Meditation, Achtsamkeit, Yoga-Philosophie und vieles mehr!
    </ng-container>
  </div>
  <ul class="is-flex is-justify-content-space-between-desktop is-overflow-x-scroll has-hidden-scrollbar px-4-mobile">
    <app-article-teaser *ngFor="let teaser of articleTeasers" [teaser]="teaser" [showImg]="true" [largeOnDesktop]="true"
      class="mr-4-not-last-touch is-hidden-last-child-on-mobile is-hidden-last-child-on-desktop"></app-article-teaser>
  </ul>
</section>