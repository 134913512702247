import { UserProfileForm } from '../api/models/user-profile-form';
import { VideoWatchedParams } from '../api/models/video-watched-params';

export namespace UserActions {
  export class ClearError {
    static readonly type = '[User] Clear error';
  }

  export class ClearMessage {
    static readonly type = '[User] Clear message';
  }

  export class FetchCurrentUser {
    static readonly type = '[User] Fetch current user';
  }

  export class FetchProfileForm {
    static readonly type = '[User] Fetch profile form';
  }

  export class RequestNewPassword {
    static readonly type = '[User] Request new password';
    constructor(public email: string) {}
  }

  export class RequestNewPasswordForEYUser {
    static readonly type = '[User] Request new password for EY users';
    constructor(public email: string) {}
  }

  export class SetNewPassword {
    static readonly type = '[User] Set new password';
    constructor(
      public password: string,
      public token: string,
    ) {}
  }

  export class SetVideoFavorite {
    static readonly type = '[User] Set video favorite';
    constructor(
      public videoId: number,
      public isFavorite: boolean,
    ) {}
  }

  export class SignIn {
    static readonly type = '[User] Sign in';
    constructor(
      public email: string,
      public password: string,
      public rememberMe: boolean,
    ) {}
  }

  export class SignOut {
    static readonly type = '[User] Sign out';
  }

  export class SignUp {
    static readonly type = '[User] Sign up';
    constructor(
      public email: string,
      public password: string,
      public wantsNewsletter: boolean,
    ) {}
  }

  export class UpdateFirstName {
    static readonly type = '[User] Update first name';
    constructor(public firstName: string) {}
  }

  export class UpdateProfileForm {
    static readonly type = '[User] Update profile form';
    constructor(public profileForm: UserProfileForm) {}
  }

  export class VideoWatched {
    static readonly type = '[User] Video watched';
    constructor(public params: VideoWatchedParams) {}
  }
}
