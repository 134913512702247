<!-- FIXME: we should not be inputting the size here but set it by using CSS viewports! -->
<div *ngIf="teaser" class="has-text-kokoda has-background-white has-border-radius-5 is-clipped has-border-quill-grey" [ngClass]="{
    'is-width-272px is-height-272px': size === 'small',
    'is-width-343px is-height-324px': size === 'smaller',
    'is-width-380px is-height-338px': size === 'default'
  }">
  <div class="is-position-relative is-clipped" [ngClass]="{
      'is-width-272px is-height-153px': size === 'small',
      'is-width-343px is-height-193px': size === 'smaller',
      'is-width-380px is-height-214px': size === 'default'
    }">
    <a [title]="teaser.short_description" [href]="pathWithBreadcrumb">
      <img [ngSrc]="imagePath" [alt]="teaser.image_alt" ngSrcset="272w, 544w, 343w, 686w, 380w, 760w"
        [sizes]="size === 'small' ? '272px' : size === 'smaller' ? '343px' : '380px'" width="560" height="316"
        [loaderParams]="{ fallback: 'production/uploads/components/video-teaser/fallback-image.jpg' }" />
      <div class="new" *ngIf="teaser.is_new" i18n="Label on Video Teaser">Neu</div>
      <div *ngIf="teaser.bookmarks_lists" class="is-position-absolute"
        [ngClass]="{ 'is-top-8px is-right-8px': size === 'small' || size === 'smaller', 'is-top-16px is-right-16px': size === 'default' }"
        (click)="openBookmarksMenu($event)">
        <svg-favorit-plus *ngIf="
            (teaser.is_favorite == undefined && teaser.bookmarks_lists == undefined) ||
              (!teaser.is_favorite && teaser.bookmarks_lists?.length == 0);
            else filled
          " class="heart"></svg-favorit-plus>
        <ng-template #filled>
          <svg-heart-filled class="heart heart-filled"></svg-heart-filled>
        </ng-template>
      </div>
      @if (draggable) {
      <div class="is-position-absolute is-top-8px is-left-50 is-right-50 has-cursor-grab">
        <svg-move></svg-move>
      </div>
      }
    </a>
  </div>
  <div class="is-clipped pt-2" [ngClass]="{
      'is-height-119px': size === 'small',
      'is-height-131px': size === 'smaller',
      'is-height-125px': size === 'default',
      'px-3': size === 'small',
      'px-4': size === 'smaller' || size === 'default'
    }">
    <!-- TODO: change to routerLink once video.component is refactored -->
    <a class="no-link" [title]="teaser.short_description" [href]="pathWithBreadcrumb">
      <div class="is-size-5-and-half is-text-ellipsis">
        {{ teaser.teacher }}
      </div>
      <h5 class="is-size-5-and-half has-text-weight-bold has-min-height-56px is-line-clamp-2 mb-1">
        {{ teaser.title }}
      </h5>
      <div class="info is-flex is-line-height-100 has-white-space-nowrap"
        [ngClass]="{ 'is-size-6-and-half': size === 'small', 'is-size-5-and-half': size === 'smaller' || size === 'default' }">
        <svg-clock class="icon mr-1"
          [ngClass]="{ 'is-tiny': size === 'small', 'is-small': size === 'smaller' || size === 'default' }"></svg-clock>
        <span class="duration">
          {{ teaser.duration_in_words }}
        </span>
        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        <app-level-label [levels]="teaser.levels"></app-level-label>
        <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        @if(locale === 'de') {
        <span class="effort">
          <ng-container i18n>Anstrengung</ng-container> &nbsp;
          <app-effort-bars [effort]="teaser.effort" />
        </span>
        } @else {
        <span class="effort">
          {{ teaser.style }}
        </span>
        }
      </div>
    </a>
  </div>
</div>