import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { CoreActions } from 'src/app/core/core.actions';
import { Viewport } from 'src/app/core/core.state';
import { ExitSvg } from '../../svg/exit/exit.svg';
import { ButtonComponent } from '../button/button.component';
import { RedirectModalSvgComponent } from './redirect-modal-svg/redirect-modal-svg.component';

@Component({
  selector: 'app-redirected-user-modal',
  standalone: true,
  imports: [ExitSvg, ButtonComponent, RedirectModalSvgComponent],
  templateUrl: './redirected-user-modal.component.html',
  styleUrl: './redirected-user-modal.component.sass',
})
export class RedirectedUserModalComponent {
  public static readonly MODAL_ID = 'RedirectedUserModal';
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public viewport$: Observable<Viewport>;
  protected buttonSize = 'large';
  constructor(private store: Store) {
    this.viewport$ = this.store.select(state => state.core?.viewport);
  }

  ngOnInit(): void {
    this.viewport$.pipe(takeUntil(this.destroy$)).subscribe(viewport => {
      this.buttonSize = viewport === 'mobile' ? 'medium' : 'large';
    });
  }
  protected closeModal(): void {
    this.store
      .dispatch(
        new CoreActions.CloseModal(RedirectedUserModalComponent.MODAL_ID),
      )
      .pipe(takeUntil(this.destroy$));
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
