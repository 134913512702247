<li *ngIf="teaser" class="program-teaser has-border-radius-5">
  <div class="teaser-wrapper">
    <a class="no-link" [title]="teaser.title" [href]="teaser.path">
      <img class="preview-image" [ngSrc]="teaser.picture_without_text | relativeUrl" [alt]="teaser.title" width="380" height="214"
        [loaderParams]="{ fallback: 'production/uploads/components/program-teaser/fallback-image.jpg' }" />
      <div class="bottom-container has-text-kokoda">
        <!-- Todo change once more data available -->
        <!-- <div class="progran-teachers is-size-6">{{ teaser.id }}</div> -->
        <div class="program-title has-text-kokoda is-size-5 mt-4">{{ teaser.title }}</div>
        <div class="program-units has-text-kokoda">
          @if(locale === 'de') { {{ teaser.unit_count }} Einheiten | } {{ teaser.video_count }} Videos | Level: {{ teaser.level }}
        </div>
      </div>
    </a>
  </div>
</li>