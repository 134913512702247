import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { VideoTeaser } from 'src/app/api/models/video-teaser';
import { Viewport } from 'src/app/core/core.state';

@Component({
  selector: 'app-videos-section',
  templateUrl: './videos-section.component.html',
  styleUrls: ['./videos-section.component.sass'],
})
export class VideosSectionComponent implements OnInit {
  public viewport$: Observable<Viewport>;
  protected videoTeasers = [];

  constructor(
    private store: Store,
    @Inject(LOCALE_ID) public locale: string,
  ) {}

  ngOnInit(): void {
    this.viewport$ = this.store.select(state => state.core.viewport);

    if (this.locale === 'de') this.videoTeasers = this.videoTeasersDE;
    else this.videoTeasers = this.videoTeasersEN;
  }

  public videoTeasersDE = [
    {
      id: 22193,
      slug: 'refine-praezise-ausrichtung-in-seitbeugen-move-feel-eat-repeat-tag-10',
      images: {
        big: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/22193/big_nicole-bongartz-NP25-T10-refine-ausrichtung-seitbeugen-still.jpg',
        fill_873_491:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/22193/fill_873_491_nicole-bongartz-NP25-T10-refine-ausrichtung-seitbeugen-still.jpg',
        fill_873_491_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/22193/fill_873_491_webp_nicole-bongartz-NP25-T10-refine-ausrichtung-seitbeugen-still.jpg.webp',
        i377_212:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/22193/i377_212_nicole-bongartz-NP25-T10-refine-ausrichtung-seitbeugen-still.jpg',
        i377_212_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/22193/i377_212_webp_nicole-bongartz-NP25-T10-refine-ausrichtung-seitbeugen-still.jpg.webp',
        thumb:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/22193/thumb_nicole-bongartz-NP25-T10-refine-ausrichtung-seitbeugen-still.jpg',
        thumb_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/22193/thumb_webp_nicole-bongartz-NP25-T10-refine-ausrichtung-seitbeugen-still.jpg.webp',
        webp: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/22193/webp_nicole-bongartz-NP25-T10-refine-ausrichtung-seitbeugen-still.jpg.webp',
      },
      title:
        'Refine: Präzise Ausrichtung in Seitbeugen - Move.Feel.Eat.Repeat: Tag 10',
      teacher: 'Nicole Bongartz',
      published_at: '2025-01-10T04:00:00.000+01:00',
      levels: [
        {
          number: 1,
          label: 'Sportliche Anfänger"',
        },
      ],
      rating_count: 658,
      rating: 5,
      comment_count: 89,
      style: 'Hatha',
      effort: 2,
      path: '/videos/refine-praezise-ausrichtung-in-seitbeugen-move-feel-eat-repeat-tag-10',
      duration_in_words: '31 Min.',
      short_description:
        'Nicole führt dich durch eine energetisierende Sequenz, nach der du dich länger und aufgerichteter fühlen wirst.',
      is_new: true,
      image_alt:
        'ME YOGA Refine: Präzise Ausrichtung in Seitbeugen - Move.Feel.Eat.Repeat: Tag 10',
    },
    {
      id: 21686,
      slug: 'tag-1-meditation-lernen-das-geheimnis-der-meditation"',
      images: {
        big: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/21686/big_timo-wahl-meditationsprogramm-geheimnis-der-meditation-still.jpg',
        fill_873_491:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21686/fill_873_491_timo-wahl-meditationsprogramm-geheimnis-der-meditation-still.jpg',
        fill_873_491_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21686/fill_873_491_webp_timo-wahl-meditationsprogramm-geheimnis-der-meditation-still.jpg.webp',
        i377_212:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21686/i377_212_timo-wahl-meditationsprogramm-geheimnis-der-meditation-still.jpg',
        i377_212_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21686/i377_212_webp_timo-wahl-meditationsprogramm-geheimnis-der-meditation-still.jpg.webp',
        thumb:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21686/thumb_timo-wahl-meditationsprogramm-geheimnis-der-meditation-still.jpg',
        thumb_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21686/thumb_webp_timo-wahl-meditationsprogramm-geheimnis-der-meditation-still.jpg.webp',
        webp: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/21686/webp_timo-wahl-meditationsprogramm-geheimnis-der-meditation-still.jpg.webp',
      },
      title: 'Tag 1 – Meditation lernen: Das Geheimnis der Meditation',
      teacher: 'Timo Wahl',
      published_at: '2024-08-26T05:00:00.000+02:00"',
      levels: [
        {
          number: -1,
          label: 'Für alle',
        },
      ],
      rating_count: 1479,
      rating: 5.0,
      comment_count: 157,
      style: 'Meditation',
      effort: 0,
      path: '/videos/tag-1-meditation-lernen-das-geheimnis-der-meditation',
      duration_in_words: '11 Min.',
      short_description:
        'Im ersten Video unseres 10-teiligen Programms „Meditieren lernen“ erfährst du, was das Geheimnis der Meditation ist.',
      is_new: false,
      image_alt:
        'Timo Wahl: Tag 1 – Meditation lernen: Das Geheimnis der Meditation',
    },
    {
      id: 22183,
      slug: 'fire-up-legs-glutes-workout',
      images: {
        big: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/22183/big_sara-lyn-NP25-T4-fire-up-legs-glutes-workout-still_b.jpg',
        fill_873_491:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/22183/fill_873_491_sara-lyn-NP25-T4-fire-up-legs-glutes-workout-still_b.jpg',
        fill_873_491_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/22183/fill_873_491_webp_sara-lyn-NP25-T4-fire-up-legs-glutes-workout-still_b.jpg.webp',
        i377_212:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/22183/i377_212_sara-lyn-NP25-T4-fire-up-legs-glutes-workout-still_b.jpg',
        i377_212_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/22183/i377_212_webp_sara-lyn-NP25-T4-fire-up-legs-glutes-workout-still_b.jpg.webp',
        thumb:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/22183/thumb_sara-lyn-NP25-T4-fire-up-legs-glutes-workout-still_b.jpg',
        thumb_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/22183/thumb_webp_sara-lyn-NP25-T4-fire-up-legs-glutes-workout-still_b.jpg.webp',
        webp: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/22183/webp_sara-lyn-NP25-T4-fire-up-legs-glutes-workout-still_b.jpg.webp',
      },
      title: 'Fire Up! Legs & Glutes Workout - Move.Feel.Eat.Repeat: Tag 4',
      teacher: 'Sara Lyn Chana',
      published_at: '2025-01-04T04:00:00.000+01:00',
      levels: [
        {
          label: 'Sportliche Anfänger',
          number: 1,
        },
      ],
      rating_count: 868,
      rating: 4.5,
      comment_count: 117,
      style: 'Pilates',
      effort: 3,
      path: '/videos/fire-up-legs-glutes-workout',
      duration_in_words: '25 Min.',
      short_description:
        'Sara Lyn führt dich durch eine Pilates-Einheit, die deine Gesäßmuskulatur zum Brennen bringt.',
      is_new: false,
      image_alt: 'Sara Lyn Chana: Fire Up Legs and Glutes Workout',
    },
    {
      id: 3840,
      slug: 'yoga-gegen-aengste-und-stress',
      images: {
        webp: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/3840/webp_yoga_angst_stress_still.jpg.webp',
        i377_212:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3840/i377_212_yoga_angst_stress_still.jpg',
        i377_212_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3840/i377_212_webp_yoga_angst_stress_still.jpg.webp',
        thumb:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3840/thumb_yoga_angst_stress_still.jpg',
        thumb_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3840/thumb_webp_yoga_angst_stress_still.jpg.webp',
        big: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/3840/big_yoga_angst_stress_still.jpg',
        fill_873_491:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3840/fill_873_491_yoga_angst_stress_still.jpg',
        fill_873_491_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/3840/fill_873_491_webp_yoga_angst_stress_still.jpg.webp',
      },
      title: 'Yoga gegen Ängste und Stress',
      teacher: 'Gabriela Bozic',
      published_at: '2022-03-31T05:05:00.000+02:00',
      levels: [
        {
          number: 1,
          label: 'Sportliche Anfänger',
        },
      ],
      rating_count: 536,
      rating: 4.5,
      comment_count: 109,
      style: 'Vinyasa Flow / Power Yoga',
      effort: 2,
      path: '/videos/yoga-gegen-aengste-und-stress',
      duration_in_words: '37 Min.',
      short_description:
        'Verschiedene Seitdehnungen und Vorbeugen geben dir mehr Erdung, Zuversicht und Ruhe. Die Praxis gibt dir das Gefühl des inneren Friedens zurück.',
      is_new: false,
      image_alt: 'yoga_gegen_angst_stress',
    },
  ] as Array<VideoTeaser>;

  public videoTeasersEN = [
    {
      id: 21244,
      slug: 'unhook-from-worry-and-embrace-inner-peace',
      images: {
        webp: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/21244/webp_0_lddxjk6e.jpg.webp',
        i377_212:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21244/i377_212_0_lddxjk6e.jpg',
        i377_212_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21244/i377_212_webp_0_lddxjk6e.jpg.webp',
        thumb:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21244/thumb_0_lddxjk6e.jpg',
        thumb_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21244/thumb_webp_0_lddxjk6e.jpg.webp',
        big: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/21244/big_0_lddxjk6e.jpg',
        fill_873_491:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21244/fill_873_491_0_lddxjk6e.jpg',
        fill_873_491_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/21244/fill_873_491_webp_0_lddxjk6e.jpg.webp',
      },
      title: 'Unhook from worry and embrace inner peace',
      teacher: 'Esther Ekhart',
      published_at: '2024-03-23T04:00:00.000+01:00',
      levels: [
        {
          number: -1,
          label: 'For All',
        },
      ],
      rating_count: 0,
      rating: 0.0,
      comment_count: 0,
      style: 'Meditation',
      effort: 0,
      path: '/videos/unhook-from-worry-and-embrace-inner-peace',
      duration_in_words: '22 min.',
      short_description:
        "This guided meditation begins by calming the nervous system through mindful breathing exercises, setting the stage for a deeper connection with your inner self. As you progress, you are gently guided to relax your body and release the day's tensions, accompanied by the reassuring reminder that, in this moment of meditation, there are no problems to solve.The meditation then transitions to exploring the essence of your being – the conscious observer of all these sensations...",
      is_new: false,
      image_alt: 'Yoga Video Unhook from worry and embrace inner peace',
    },
    {
      id: 7172,
      slug: '3in1-practice',
      images: {
        webp: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/7172/webp_0_s8ypxm1q.jpg.webp',
        i377_212:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/7172/i377_212_0_s8ypxm1q.jpg',
        i377_212_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/7172/i377_212_webp_0_s8ypxm1q.jpg.webp',
        thumb:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/7172/thumb_0_s8ypxm1q.jpg',
        thumb_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/7172/thumb_webp_0_s8ypxm1q.jpg.webp',
        big: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/7172/big_0_s8ypxm1q.jpg',
        fill_873_491:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/7172/fill_873_491_0_s8ypxm1q.jpg',
        fill_873_491_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/7172/fill_873_491_webp_0_s8ypxm1q.jpg.webp',
      },
      title: '3 in 1 practice',
      teacher: 'David Kam',
      published_at: '2021-09-24T04:00:00.000+02:00',
      levels: [
        {
          number: 1,
          label: 'Athletic Beginner',
        },
        {
          number: 2,
          label: 'Intermediate',
        },
      ],
      rating: 0.0,
      comment_count: 0,
      style: 'Vinyasa Flow',
      effort: 0,
      path: '/videos/3in1-practice',
      duration_in_words: '50 min.',
      short_description:
        'A complete practice of breath work, movement and meditation. A strong practice guaranteed to energize and revitalize...',
      is_new: false,
      image_alt: 'Yoga Video 3 in 1 practice',
    },
    {
      id: 5957,
      slug: 'perfect-45-flow',
      images: {
        webp: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/5957/webp_0_l4tkw5dm.jpg.webp',
        i377_212:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/5957/i377_212_0_l4tkw5dm.jpg',
        i377_212_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/5957/i377_212_webp_0_l4tkw5dm.jpg.webp',
        thumb:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/5957/thumb_0_l4tkw5dm.jpg',
        thumb_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/5957/thumb_webp_0_l4tkw5dm.jpg.webp',
        big: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/5957/big_0_l4tkw5dm.jpg',
        fill_873_491:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/5957/fill_873_491_0_l4tkw5dm.jpg',
        fill_873_491_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/5957/fill_873_491_webp_0_l4tkw5dm.jpg.webp',
      },
      title: 'Perfect 45 Flow',
      teacher: 'Tashi Dawa',
      published_at: '2022-12-20T04:00:00.000+01:00',
      levels: [
        {
          number: -1,
          label: 'For All',
        },
      ],
      rating_count: 0,
      rating: 0.0,
      comment_count: 0,
      style: 'Hatha',
      effort: 0,
      path: '/videos/perfect-45-flow',
      duration_in_words: '46 min.',
      short_description:
        'Join Tashi for a whole body 45min flow practice. This sequence is based around sun salutations so may recognize and flow into your own style of transitions or modifications...',
      is_new: false,
      image_alt: 'Yoga Video Perfect 45 Flow',
    },
    {
      id: 4392,
      slug: 'mit-rueckbeugen-angstfrei-nach-vorne-schauen',
      images: {
        webp: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/webp_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg.webp',
        i377_212:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/i377_212_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg',
        i377_212_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/i377_212_webp_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg.webp',
        thumb:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/thumb_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg',
        thumb_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/thumb_webp_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg.webp',
        big: 'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/big_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg',
        fill_873_491:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/fill_873_491_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg',
        fill_873_491_webp:
          'https://cdn.yogaeasy.de/production/uploads/video/video_still/4392/fill_873_491_webp_moritz-ulrich-jivamuktiyoga-rueckbeugen-heraufschauenderhund-still.jpg.webp',
      },
      title: 'Mit Rückbeugen angstfrei nach vorne schauen',
      teacher: 'Moritz Ulrich',
      published_at: '2022-11-17T05:14:00.000+01:00',
      levels: [
        {
          number: 2,
          label: 'Mittelstufe',
        },
      ],
      rating_count: 434,
      rating: 5.0,
      comment_count: 76,
      style: 'Jivamukti',
      effort: 4,
      path: '/videos/mit-rueckbeugen-angstfrei-nach-vorne-schauen',
      duration_in_words: '43 Min.',
      short_description:
        'Eine Allround-Sequenz mit Fokus auf Rückbeugen, die dich unterstützen können, bedingungslos zu lieben und dich lieben zu lassen.',
      is_new: false,
      image_alt: 'Mit Rückbeugen angstfrei nach vorne schauen – Moritz Ulrich',
    },
  ] as Array<VideoTeaser>;
}
