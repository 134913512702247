import { Component } from '@angular/core';

@Component({
  selector: 'app-redirect-modal-svg',
  standalone: true,
  imports: [],
  templateUrl: './redirect-modal-svg.component.html',
  styleUrl: './redirect-modal-svg.component.sass',
})
export class RedirectModalSvgComponent {}
