<div class="redirect-modal">
  <input type="text" style="display: none" />
  <div class="ye-modal-icon-container">
    <svg-exit class="ye-modal-close-icon" (click)="closeModal()"></svg-exit>
  </div>
  <div class="modal-top-content">
    <div class="ye-modal-header">Welcome to YogaEasy!</div>
    <div>
      <app-redirect-modal-svg></app-redirect-modal-svg>
    </div>
    <div class="ye-modal-text">
      EkhartYoga has joined with YogaEasy to bring you an even richer experience - same great content, new
      possibilities!</div>
    <div class="ye-modal-button-container">
      <app-button size="{{buttonSize}}" (onClick)="closeModal()" class="ye-modal-button">Start exploring
        now</app-button>
    </div>
  </div>

  <div class="modal-bottom-content">
    <div class="ye-modal-subheader">
      Already an EkhartYoga member?</div>
    <div class="ye-modal-text mb-2">
      Please set a new password to activate your account and access your saved content.</div>
    <div class="ye-modal-button-container">
      <app-button size="{{buttonSize}}" [href]="'/user/password_reset'" type="secondary" class="ye-modal-button">Continue</app-button>
    </div>
  </div>

</div>