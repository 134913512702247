<button class="button is-primary has-text-kokoda is-inverted is-rounded-icon mr-0 has-background-canara"
  [ngClass]="{ 'has-background-kokoda': active }" [ngbTooltip]="content" placement="bottom-right" triggers="click" [autoClose]="'outside'"
  tooltipClass="navbar-menu-tooltip search-tooltip" #t="ngbTooltip" (shown)="active = true" (hidden)="active = false">
  <svg-search class="icon" [ngClass]="{ 'has-text-white': active, 'has-background-kokoda': active }"></svg-search>
</button>

<ng-template #content>
  <div class="d-flex is-align-items-center p-2 is-width-100 is-height-100">
    <button class="button is-primary has-text-kokoda is-inverted is-rounded-icon mr-2 mb-0" [ngClass]="{ 'is-loading': isLoading }"
      (click)="executeSearch(t)">
      <svg-search class="icon"></svg-search>
    </button>
    <input autofocus class="input is-min-width-220px has-text-kokoda" type="text" placeholder="Suche nach Videos & mehr..."
      [(ngModel)]="searchTerm" (keyup.enter)="executeSearch(t)" i18n-placeholder />
    <span class="has-text-kokoda ml-2 is-clickable" (click)="t.close()" i18n>Abbrechen</span>
  </div>
</ng-template>