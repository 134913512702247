<app-top-section></app-top-section>
@if(locale==='de'){
<app-benefits-section class="is-block mb-5-and-half-mobile mb-6-tablet"></app-benefits-section>
} @else {
<app-migration-info></app-migration-info>
}
<app-videos-section class="is-block mb-5-and-half-mobile mb-6-tablet mt-6"></app-videos-section>
<app-cta-button-section class="is-hidden-touch is-block mb-5-and-half-mobile mb-6-tablet"></app-cta-button-section>
<app-live-section class="is-block mb-5-and-half-mobile mb-6-tablet"></app-live-section>
<app-teachers-section class="is-block mb-5-and-half-mobile mb-6-tablet"></app-teachers-section>
<app-programs-section class="is-block mb-5-and-half-mobile mb-6-tablet"></app-programs-section>
<app-cta-button-section class="is-hidden-touch is-block mb-5-and-half-mobile mb-6-tablet"></app-cta-button-section>
<app-articles-section class="is-block mb-5-and-half-mobile mb-6-tablet"></app-articles-section>
<mobile-app-section class="is-block mb-5-and-half-mobile mb-6-tablet">
  <svg-app-pictogram color="#CAE7E0" class="mt-5 mb-4"></svg-app-pictogram>
  <h2 class="is-size-4 is-size-3-tablet has-text-deep-sea-green has-text-weight-bold has-text-centered mb-2 mx-4 px-4">
    <ng-container i18n>
      Bleib flexibel mit der App
    </ng-container>
  </h2>
  <div class="is-size-5-and-half has-text-kokoda has-text-centered mb-5 is-width-100-tablet-only px-2-tablet-only is-width-341">
    <ng-container i18n>
      Ob zu Hause oder unterwegs – dein Yoga ist immer dabei – und dank der praktischen Downloadfunktion kannst du auch Videos üben, wenn du
      offline bist.
    </ng-container>
  </div>
  <app-appstore-buttons class="has-text-centered" />
  @if(locale==='de'){
  <img ngSrc="production/uploads/components/mobile-app-section/app-banner-mobile.jpg" ngSrcset="340w, 680w, 460w, 920w, 580w, 1140w"
    width="750" height="672" alt="Yogaeasy Apps" class="img-mobile" />
  } @else {
  <img ngSrc="production/uploads/components/mobile-app-section/app-banner-mobile-EN.jpg" ngSrcset="340w, 680w, 460w, 920w, 580w, 1140w"
    width="750" height="672" alt="Yogaeasy Apps" class="img-mobile" />
  }
</mobile-app-section>
<app-academy-section class="is-block mb-5-and-half-mobile mb-6-tablet"></app-academy-section>
<app-ratings-carousel class="is-block mb-5-and-half-mobile mb-6-tablet"></app-ratings-carousel>
<app-recommended-by-section class="is-block mb-5-and-half-mobile mb-6-tablet"></app-recommended-by-section>
<app-cta-button-section class="is-hidden-touch is-block mb-5-and-half-mobile mb-6-tablet"></app-cta-button-section>