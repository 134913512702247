import { Inject, Injectable, LOCALE_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LiveClassesDeeplinkService {
  private static readonly SCHEMA = 'yogaeasy://';
  private static readonly HOSTS = {
    de: 'www.yogaeasy.de',
    en: 'www.yogaeasy.com',
  };
  private static readonly PATH_LIVE_CLASSES = 'live-classes';

  constructor(@Inject(LOCALE_ID) private locale: string) {}

  private static getHost(locale: string): string {
    return (
      LiveClassesDeeplinkService.HOSTS[locale] ||
      LiveClassesDeeplinkService.HOSTS.en
    );
  }

  makeDeeplink(classId: number): string {
    const schema = LiveClassesDeeplinkService.SCHEMA;
    const host = LiveClassesDeeplinkService.getHost(this.locale);
    const path = LiveClassesDeeplinkService.PATH_LIVE_CLASSES;

    return `${schema}${host}/${path}/${classId}`;
  }
}
