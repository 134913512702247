import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Inject, Input, LOCALE_ID } from '@angular/core';
import { ProgramTeaser } from 'src/app/api/models/program-teaser';
import { RelativeUrlPipe } from '../relative-url/relative-url.pipe';

@Component({
  selector: 'app-program-new-teaser',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, RelativeUrlPipe],
  templateUrl: './program-new-teaser.component.html',
  styleUrl: './program-new-teaser.component.sass',
})
export class ProgramNewTeaserComponent {
  @Input() teaser: ProgramTeaser;
  constructor(@Inject(LOCALE_ID) public locale: string) {}
  //Todo - delete the below code
  // teaser: ProgramTeaser = {
  //   id: '15-minuten-morgen-yoga-routine',
  //   title: '15 Minuten Morgen-Yoga-Routine',
  //   path: '/programme/15-minuten-morgen-yoga-routine',
  //   is_new: false,
  //   picture_with_text:
  //     'https://cdn.yogaeasy.de/production/uploads/program/picture_with_text/506/ye-15-min-morgen-yoga-keyv-1314x739.jpg',
  //   // picture_without_text:
  //   //   'https://cdn.yogaeasy.de/production/uploads/program/picture_without_text/506/ye-15-min-morgen-yoga-cat-visuals-blank-900x508.jpg',
  //   new_program_picture:
  //     'https://cdn.yogaeasy.de/production/uploads/program/new_program_picture/506/ye-15-min-morgen-yoga-neu-441x413.jpg',
  //   description:
  //     '<p>Geh&ouml;rst du auch zu den Menschen, die froh sind, wenn sie es morgens vor der Arbeit schaffen, sich rechtzeitig aus dem Bett zu sch&auml;len, einen Kaffee zu kochen und die Z&auml;hne zu putzen?</p>\r\n\r\n<p><strong>Immer wieder nimmst du dir vor, morgens auf die Matte zu gehen, </strong>weil es doch eigentlich so guttut &ndash; und dann ist aber doch jede Minute am Morgen Gold wert und die Matte bleibt eingerollt.</p>\r\n\r\n<p>Glaub uns: Das kennen wir nur zu gut!&nbsp;<br />\r\nUnd gleichzeitig wissen wir, wie sehr es sich lohnt, diese Zeit am Morgen &ndash; egal wie kurz sie ist &ndash; ganz f&uuml;r dich und einen <strong>ausgeglichenen Start in den Tag </strong>zu reservieren.</p>\r\n\r\n<p>Deshalb haben wir dir in diesem Programm <strong>unsere besten kurzen Videos f&uuml;r den Morgen </strong>zusammengestellt und geben dir dazu wertvolle Tipps mit, wie du es auch wirklich schaffst, auf dem Weg in deinen Tag zumindest f&uuml;r 15 Minuten auf deiner Matte Halt zu machen. 15 Minuten, in denen nichts weiter wichtig ist als du, dein Atem und der jetzige Moment.&nbsp;</p>\r\n\r\n<p>In diesem Programm <strong>verlierst du morgens keine Zeit</strong>, weil du dir erstmal ein Video aussuchen musst, dich nicht entscheiden kannst und am Ende gar nicht auf die Matte kommst. Denn du bekommst von uns jeden Morgen per E-Mail dein Video f&uuml;r den Tag &ndash; du brauchst nur deine Matte auszurollen und auf Play zu klicken.</p>\r\n\r\n<p>Klingt gut?</p>\r\n\r\n<p><strong>Dann melde dich jetzt an und mache&nbsp;jeden Tag zu deinem Tag!</strong></p>\r\n',
  //   video_count: 13,
  //   level: 1,
  //   effort: 'leicht bis schwer',
  //   style: 'Vinyasa Flow, Anusara, Hatha, Atemübungen',
  //   unit_count: 13,
  // };
}
