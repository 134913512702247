import { Component } from '@angular/core';
import { EyYeMigrationLogoComponent } from '../../svg/ey-ye-migration-logo/ey-ye-migration-logo.component';

@Component({
  selector: 'app-migration-info',
  standalone: true,
  imports: [EyYeMigrationLogoComponent],
  templateUrl: './migration-info.component.html',
  styleUrl: './migration-info.component.sass',
})
export class MigrationInfoComponent {}
