import {
  CommonModule,
  NgOptimizedImage,
  provideImageKitLoader,
} from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxsModule } from '@ngxs/store';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BookmarksModule } from '../bookmarks/bookmarks.module';
import { FilterModule } from '../filter/filter.module';
import { ArticleTeaserComponent } from '../shared/article-teaser/article-teaser.component';
import { BookmarksListFavoriteTeaserComponent } from '../shared/bookmarks-list-favorite-teaser/bookmarks-list-favorite-teaser.component';
import { BookmarksListTeaserComponent } from '../shared/bookmarks-list-teaser/bookmarks-list-teaser.component';
import { ButtonComponent } from '../shared/button/button.component';
import { BypassSecurityPipe } from '../shared/bypass-security.pipe';
import { LoadingPageComponent } from '../shared/loading-page/loading-page.component';
import { MoreTeaserComponent } from '../shared/more-teaser/more-teaser.component';
import { PodcastTeaserComponent } from '../shared/podcast-teaser/podcast-teaser.component';
import { ProgramNewTeaserComponent } from '../shared/program-new-teaser/program-new-teaser.component';
import { ProgramTeaserComponent } from '../shared/program-teaser/program-teaser.component';
import { TeacherTeaserComponent } from '../shared/teacher-teaser/teacher-teaser.component';
import { TeaserSliderEmptyMessageComponent } from '../shared/teaser-slider-empty-message/teaser-slider-empty-message.component';
import { TeaserSliderComponent } from '../shared/teaser-slider/teaser-slider.component';
import { VideoTeaserGhostComponent } from '../shared/video-teaser/video-teaser-ghost/video-teaser-ghost.component';
import { VideoTeaserComponent } from '../shared/video-teaser/video-teaser.component';
import { YeLoadingSpinnerComponent } from '../shared/ye-loading-spinner/ye-loading-spinner.component';
import { ArrowBackSvg } from '../svg/arrow-back/arrow-back.svg';
import { ExitSvg } from '../svg/exit/exit.svg';
import { ExternalLinkSvg } from '../svg/external-link/external-link.svg';
import { HeartFilledSvg } from '../svg/heart-filled/heart-filled.svg';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardState } from './dashboard.state';
import { LiveSectionComponent } from './live-section/live-section.component';
import { ShowMorePageComponent } from './show-more-page/show-more-page.component';
import { AdvancedVideosComponent } from './show-more-page/show-more-pages/advanced-videos.component';
import { BackVideosComponent } from './show-more-page/show-more-pages/back-videos.component';
import { BeginnerVideosComponent } from './show-more-page/show-more-pages/beginner-videos.component';
import { EstherVideosComponent } from './show-more-page/show-more-pages/esther-videos.component';
import { EveningVideosComponent } from './show-more-page/show-more-pages/evening-videos.component';
import { FavoritedVideosComponent } from './show-more-page/show-more-pages/favorited-videos.component';
import { FitnessVideosComponent } from './show-more-page/show-more-pages/fitness-videos.component';
import { LastViewedVideosComponent } from './show-more-page/show-more-pages/last-viewed-videos.component';
import { LiveClassesVideosComponent } from './show-more-page/show-more-pages/live-classes-videos.component';
import { MeditationVideosComponent } from './show-more-page/show-more-pages/meditation-videos.component';
import { MorningVideosComponent } from './show-more-page/show-more-pages/morning-videos.component';
import { NeckShoulderVideosComponent } from './show-more-page/show-more-pages/neck-shoulder-videos.component';
import { NewVideosComponent } from './show-more-page/show-more-pages/new-videos.component';
import { PopularProgramsComponent } from './show-more-page/show-more-pages/popular-programs.component';
import { PopularVideosComponent } from './show-more-page/show-more-pages/popular-videos.component';
import { RecommendedVideosComponent } from './show-more-page/show-more-pages/recommended-videos.component';
import { ShortVideosComponent } from './show-more-page/show-more-pages/short-videos.component';
import { StressVideosComponent } from './show-more-page/show-more-pages/stress-videos.component';
import { WellRatedVideosComponent } from './show-more-page/show-more-pages/well-rated-videos.component';
import { YinVideosComponent } from './show-more-page/show-more-pages/yin-videos.component';
import { CuratedListsComponent } from './tab-navigation/discover/curated-lists/curated-lists.component';
import { DiscoverComponent } from './tab-navigation/discover/discover.component';
import { NewsItemComponent } from './tab-navigation/discover/news-item/news-item.component';
import { MyProgramsPageComponent } from './tab-navigation/my-programs-page/my-programs-page.component';
import { MyVideosPageComponent } from './tab-navigation/my-videos-page/my-videos-page.component';
import { TabNavigationComponent } from './tab-navigation/tab-navigation.component';

@NgModule({
  declarations: [
    AdvancedVideosComponent,
    BackVideosComponent,
    BeginnerVideosComponent,
    CuratedListsComponent,
    DiscoverComponent,
    EstherVideosComponent,
    EveningVideosComponent,
    FavoritedVideosComponent,
    FitnessVideosComponent,
    LastViewedVideosComponent,
    LiveClassesVideosComponent,
    MeditationVideosComponent,
    MorningVideosComponent,
    MyProgramsPageComponent,
    MyVideosPageComponent,
    NeckShoulderVideosComponent,
    NewVideosComponent,
    NewsItemComponent,
    PopularProgramsComponent,
    PopularVideosComponent,
    RecommendedVideosComponent,
    ShortVideosComponent,
    ShowMorePageComponent,
    StressVideosComponent,
    TabNavigationComponent,
    LiveSectionComponent,
    WellRatedVideosComponent,
    YinVideosComponent,
  ],
  providers: [provideImageKitLoader('https://ik.imagekit.io/ye')],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    NgxsModule.forFeature([DashboardState]),
    NgOptimizedImage,
    FormsModule,
    ReactiveFormsModule,
    ArticleTeaserComponent,
    BookmarksListFavoriteTeaserComponent,
    BookmarksListTeaserComponent,
    BookmarksModule,
    ButtonComponent,
    BypassSecurityPipe,
    CarouselModule,
    FilterModule,
    HeartFilledSvg,
    InfiniteScrollModule,
    LoadingPageComponent,
    MoreTeaserComponent,
    PodcastTeaserComponent,
    ProgramTeaserComponent,
    TeacherTeaserComponent,
    TeaserSliderComponent,
    TeaserSliderEmptyMessageComponent,
    VideoTeaserComponent,
    VideoTeaserGhostComponent,
    YeLoadingSpinnerComponent,
    ArrowBackSvg,
    ExitSvg,
    ExternalLinkSvg,
    ProgramNewTeaserComponent,
  ],
})
export class DashboardModule {}
