import { AsyncPipe, DOCUMENT, NgOptimizedImage } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { LiveClass, LiveClassLink } from '../api/models/live-class';
import { ButtonComponent } from '../shared/button/button.component';
import { H1DecoComponent } from '../shared/h1-deco/h1-deco.component';
import { PageHeaderComponent } from '../shared/page-header/page-header.component';
import { Utility } from '../shared/utility';
import { LiveClassesDeeplinkService } from './live-classes-deeplink.service';
import { LiveClassesActions } from './live-classes.actions';

@Component({
  selector: 'app-live-classes',
  standalone: true,
  imports: [
    ButtonComponent,
    PageHeaderComponent,
    NgOptimizedImage,
    ButtonComponent,
    AsyncPipe,
    H1DecoComponent,
  ],
  templateUrl: './live-classes.component.html',
  styleUrl: './live-classes.component.sass',
})
export class LiveClassesComponent implements OnInit, OnDestroy {
  private readonly ROUTE_MODE_MOBILE = 'mobile';
  private readonly SIGN_IN_ROUTER_LINK = '/user/sign_in?next=%10live-classes';
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public liveClasses$: Observable<LiveClass[]>;
  public liveClassLink$: Observable<LiveClassLink>;
  public isLoggedIn$: Observable<boolean>;
  public startDates: Date[];
  public isMobileMode = false;
  public utility = Utility;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private liveClassesDeeplinkService: LiveClassesDeeplinkService,
    private window: Window,
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.isMobileMode = params.get('mode') === this.ROUTE_MODE_MOBILE;
      if (this.isMobileMode) {
        this.addViewportMetaTag();
      }
    });
    this.store.dispatch(new LiveClassesActions.FetchAll());
    this.liveClasses$ = this.store.select(
      state => state.liveClasses.liveClasses,
    );
    this.isLoggedIn$ = this.store
      .select(state => state.user.user)
      .pipe(map(user => user !== null));
  }

  joinClass(id: number): void {
    this.isLoggedIn$.pipe(takeUntil(this.destroy$)).subscribe(isLoggedIn => {
      if (this.isMobileMode) {
        this.joinClassWithDeeplink(id);
      } else if (isLoggedIn) {
        this.store.dispatch(new LiveClassesActions.RequestLiveClassLink(id));
        this.liveClassLink$ = this.store.select(
          state => state.liveClasses.liveClassLink,
        );
        this.liveClassLink$
          .pipe(takeUntil(this.destroy$))
          .subscribe(liveClassLink => {
            this.window.location.href = liveClassLink.url;
          });
      } else {
        this.window.location.href = this.SIGN_IN_ROUTER_LINK;
      }
    });
  }

  private joinClassWithDeeplink(id: number): void {
    this.window.location.href =
      this.liveClassesDeeplinkService.makeDeeplink(id);
  }

  getFormattedDates(liveClasses$: Observable<LiveClass[]>): string[] {
    const formattedDates: string[] = [];

    liveClasses$.pipe(takeUntil(this.destroy$)).subscribe(liveClasses => {
      // biome-ignore lint/complexity/noForEach: <explanation>
      liveClasses.forEach(liveClass => {
        const date = new Date(liveClass.scheduled_at);
        const formattedDate = date.toLocaleDateString('en-GB', {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
        });
        formattedDates.push(formattedDate);
      });
    });

    return formattedDates;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  addViewportMetaTag(): void {
    const meta = this.renderer.createElement('meta');
    this.renderer.setAttribute(meta, 'name', 'viewport');
    this.renderer.setAttribute(
      meta,
      'content',
      'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no',
    );
    this.renderer.appendChild(this.document.head, meta);
  }
}
