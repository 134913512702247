import { AsyncPipe, NgClass } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, map, skip, startWith, takeUntil } from 'rxjs';
import { User } from 'src/app/api/models/user';
import { RouterStateParams } from 'src/app/app-routing.serializer';
import { RoutesWithPathMatch, Utility } from 'src/app/shared/utility';
import { ExitCircleSvg } from '../../svg/exit-circle/exit-circle.svg';

const HIDE_ON_ROUTES: RoutesWithPathMatch = [
  { path: '/', pathMatch: 'full' },
  { path: '/prices', pathMatch: 'prefix' },
  { path: '/free', pathMatch: 'prefix' },
  { path: '/campaign', pathMatch: 'prefix' },
  { path: '/user/account_activation', pathMatch: 'prefix' },
  { path: '/user/password_reset', pathMatch: 'prefix' },
  { path: '/user/sign_in', pathMatch: 'prefix' },
];

@Component({
  selector: 'app-migration-banner',
  standalone: true,
  imports: [ExitCircleSvg, AsyncPipe, NgClass],
  templateUrl: './migration-banner.component.html',
  styleUrl: './migration-banner.component.sass',
})
export class MigrationBannerComponent implements OnInit {
  public hideAnnouncement$: Observable<boolean>;
  private routerState$: Observable<RouterStateParams>;
  public isLoggedIn: boolean;
  public destroy$: Subject<boolean> = new Subject<boolean>();
  @Select(state => state.user?.user) user$: Observable<User>;
  protected isDisplayNone = false;
  protected isMigrationBannerSessioned: boolean;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private store: Store,
  ) {
    this.isMigrationBannerSessioned =
      sessionStorage.getItem('migrationBanner') === 'true';
  }

  ngOnInit() {
    this.routerState$ = this.store.select(state => state.router.state);
    if (this.routerState$) {
      this.hideAnnouncement$ = this.routerState$.pipe(
        map(routerState => {
          return Utility.routeMatches(routerState?.url, HIDE_ON_ROUTES);
        }),
        startWith(true),
      );
    }
    this.user$.pipe(takeUntil(this.destroy$), skip(1)).subscribe(user => {
      this.isLoggedIn = !!user;
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  close() {
    // this.store.dispatch(new CoreActions.CloseAnnouncement(1001));
    this.isDisplayNone = true;
    sessionStorage.setItem('migrationBanner', 'true');
  }
}
